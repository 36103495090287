#profileIcon {
	position: relative;
	border-radius: 50%;
	transition: 550ms;
	border: 3px solid #F6B17A;
	transform: scale(1.4);
	-webkit-transform: scale(1.4);
    width: 20%;
}

#profileIcon:hover {
	transform: scale(1.6);
	-webkit-transform: scale(1.6);
	cursor: pointer;
}

.aboutMe {
    position: relative;
    color: #fff;
    font-size: 1.4rem;
    font-family: "Playfair Display", serif;
    margin-top: -25%;
    left: 30%;
}

.aboutMediv {
    position: relative;
    color: #fff;
    font-size: 1.4rem;
    font-family: "Playfair Display", serif;
    margin-top: 10%;
}

.aboutMeText {
    position: relative;
    color: #fff;
    margin-top: -2%;
}

.subText {
    position: absolute;
    color: #fff;
    font-size: 1.1rem;
    font-family: "Playfair Display", serif;
    font-style: italic;
    margin-top: 5%;
    left: 5.6%;
}

.github {
    position: absolute;
    color: #fff;
    width: 6%;
    left: 6.6%;
    bottom: -8%;
}

.github:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    cursor: pointer;
}