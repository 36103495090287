@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.container {
  display: inline-block flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 64rem;
  min-height: 100%;
  background-color: #424769;
  padding: 4rem 1rem;
}

body {
  background-color: #424769;
}

h1 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

#title {
  position: relative;
  color: #fff;
  font-size: 2.8rem;
  font-family: "Playfair Display", serif;
  text-align: center;
  margin: auto;
  margin-top: 4%;
}

.items {
  position: relative;
  font-size: 2.8rem;
  font-family: "Playfair Display", serif;
  margin: auto;
}

.center {
  text-align: center;
}

.toFadeIn {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  animation-delay: 0.3s;
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}


.big {
  min-height: 90.4vh;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  background-color: #2D3250;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  font-family: "Playfair Display", serif;
  padding-top: 1rem;
  padding-bottom: 2rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
  border: #2D3250;
}

.footer a {
  text-decoration: none;
  color: #fff;
}